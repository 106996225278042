import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Rates from './pages/Rates';
import Home from './pages/Home';
import Navbar from './pages/Navbar';
import NoPage from './pages/NoPage';
import SearchResult from './pages/SearchResult';
import Portfolio from './pages/Portfolio';
import { PortfolioProvider } from './components/portfolio/portfoliocontext';
import Register from './pages/Register';
import Login from './pages/Login';
import { AuthProvider } from './components/infrastructure/authContext';
import Clients from './pages/Clients';
import ClientResults from './pages/ClientResults';


function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/rates" element={<Rates />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/searchResult/:search" element={<SearchResult />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path='/clients' element={<Clients />} />
        <Route path="/client/:clientId" element={<ClientResults />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <PortfolioProvider>
        <App />
      </PortfolioProvider>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
