import React, { useEffect, useState } from "react";
import SearchBar from "../components/infrastructure/search";
import { useParams } from "react-router";
import StockChart from "../components/stocksearch/stockpricechart";
import Footer from "../components/infrastructure/footer";
import ReturnsTable from "../components/stocksearch/returnstable";
import BusinessDescription from "../components/stocksearch/businessdescription";
import VolumeChart from "../components/stocksearch/volumechart";
import DividendsChart from "../components/stocksearch/dividends";
import IpoDate from "../components/stocksearch/ipodate";
import PortfolioFunctionButton from "../components/portfolio/portfoliobutton";
import "../components/styles/SearchResults.style.css";
import StatisticTable from "../components/tables/searchstatistics";

export default function SearchResult() {
  const [price, setPrice] = useState("");
  const [ytdreturn, setYtdreturn] = useState("");
  const [name, setName] = useState("");

  let { search } = useParams();
  const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

  const fetchPrice = async (search) => {
    try {
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/currentPrice`);
      const data = await response.json();
      setPrice(data);
    } catch (e) {
      console.log(e);
      setPrice(0);
    }
  };

  const fetchYTDreturn = async (search) => {
    try {
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/returns/ytd`);
      const data = await response.json();
      setYtdreturn(data.toFixed(2));
    } catch (e) {
      console.log(e);
      setYtdreturn(0);
    }
  };

  const fetchLongName = async (search) => {
    try {
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/cikname`);
      const data = await response.json();
      setName(data);
    } catch (e) {
      console.log(e);
      setName(0);
    }
  };

  useEffect(() => {
    fetchLongName(search);
    fetchPrice(search);
    fetchYTDreturn(search);
  }, [search]);

  return (
    <div className="stock-search">
      <SearchBar />
      <div className="container">
        <div className="above-columns">
          <div className="column">
            <h2>{search.toUpperCase()}</h2>
            <PortfolioFunctionButton addItem={search} />
            <h3>Price: {price}</h3>
            <p>
              YTD: {ytdreturn}%
              <br />
              <StatisticTable ticker={search} />
              <IpoDate stock={search} />
            </p>
          </div>

          <div className="column">
            <h2>{name}</h2>
            <StockChart stock={search} height={160} width={'95%'} />
            <div className="volume">
              <h3>Volume</h3>
              <VolumeChart stock={search} />
            </div>
          </div>
        </div>

        <div className="below-columns">
          <div className="column">
            <BusinessDescription stock={search} />
          </div>
        </div>

        <div className="below-columns">
          <div className="column">
            <ReturnsTable stock={search} />
          </div>
        </div>

        <div className="below-columns">
          <div className="column">
            <h2>Dividend History</h2>
            <h3>{name}</h3>
            <DividendsChart stock={search} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
