import React, { useState } from 'react';
import { usePortfolio } from '../portfolio/portfoliocontext';
import useApi from '../hooks/userAPI';

const AddItemForm = () => {
  const { state, dispatch } = usePortfolio();
  const { getPortfolios, updatePortfolio } = useApi();
  const [newItem, setNewItem] = useState({ ticker: '', shares: '' });

  const handleAddItem = async (e) => {
    console.log('handling item...');
    e.preventDefault();
    if (newItem.ticker.trim() && newItem.shares) {
      try {
        const portfolios = await getPortfolios();
        const selectedPortfolio = portfolios.find(
          portfolio => portfolio.portfolioId === state.selectedPortfolio
        );
        const existingItems = selectedPortfolio ? JSON.parse(selectedPortfolio.description) : [];
        const updatedItems = [
          ...existingItems,
          { ticker: newItem.ticker.trim(), shares: parseInt(newItem.shares, 10) }
        ];

        dispatch({
          type: 'ADD_TO_PORTFOLIO',
          payload: { ticker: newItem.ticker.trim(), shares: parseInt(newItem.shares, 10) }
        });

        await updatePortfolio(state.selectedPortfolio, {
          portfolioName: selectedPortfolio.portfolioName,
          items: updatedItems
        });
        console.log('Portfolio updated successfully');

        setNewItem({ ticker: '', shares: '' });
      } catch (error) {
        console.error('Error updating portfolio:', error);
      }
    } else {
      console.log('Error adding item');
    }
  };

  return (
    <form onSubmit={handleAddItem}>
      <input
        type="text"
        value={newItem.ticker}
        onChange={(e) => setNewItem({ ...newItem, ticker: e.target.value })}
        placeholder="Ticker"
        required
      />
      <input
        type="number"
        value={newItem.shares}
        onChange={(e) => setNewItem({ ...newItem, shares: e.target.value })}
        placeholder="Shares"
        required
      />
      <button type="submit">Add Item</button>
    </form>
  );
};

export default AddItemForm;
