import React, { useEffect, useState, useContext } from 'react';
import SearchBar from '../components/infrastructure/search';
import { usePortfolio } from '../components/portfolio/portfoliocontext';
import PortfolioList from '../components/portfolio/portfoliolist';
import Footer from '../components/infrastructure/footer';
import '../components/styles/Portfolio.style.css';
import useApi from '../components/hooks/userAPI';
import { AuthContext } from '../components/infrastructure/authContext';
import AddItemForm from '../components/portfolio/AddItemForm';
import PortfolioItems from '../components/portfolio/portfolioitems';
import LoadingIcon from '../components/infrastructure/loadingicon'; // Import the loading icon

const Portfolio = () => {
  const { state, dispatch } = usePortfolio();
  const { isAuthenticated } = useContext(AuthContext);
  const { createPortfolio, getPortfolios, deletePortfolio } = useApi();
  const [newPortfolioName, setNewPortfolioName] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPortfolios = async () => {
      if (isAuthenticated) {
        try {
          const response = await getPortfolios();
          console.log("Fetched portfolios:", response);

          if (Array.isArray(response) && response.length > 0) {
            const portfolioMap = response.reduce((acc, portfolio) => {
              acc[portfolio.portfolioId] = {
                ...portfolio,
                items: Array.isArray(portfolio.items) ? portfolio.items : [],
              };
              return acc;
            }, {});

            dispatch({ type: 'SET_PORTFOLIO', payload: portfolioMap });

            const savedPortfolioId = localStorage.getItem('selectedPortfolioId');
            if (savedPortfolioId && portfolioMap[savedPortfolioId]) {
              dispatch({ type: 'SELECT_PORTFOLIO', payload: savedPortfolioId });
            } else if (response.length > 0) {
              dispatch({ type: 'SELECT_PORTFOLIO', payload: response[0].portfolioId });
            }
          } else {
            dispatch({ type: 'SET_PORTFOLIO', payload: {} });
            dispatch({ type: 'SELECT_PORTFOLIO', payload: null });
          }
        } catch (error) {
          console.error('Error fetching portfolios:', error);
        } finally {
          setLoading(false); // Set loading to false after fetching portfolios
        }
      }
    };

    fetchPortfolios();
  }, [isAuthenticated, dispatch]);

  const handleDelete = async (index) => {
    const portfolioItem = state.portfolios[state.selectedPortfolio]?.items[index];

    if (isAuthenticated && portfolioItem) {
      try {
        await deletePortfolio(state.selectedPortfolio);
        const response = await getPortfolios();
        const portfolios = response;
        const portfolioMap = portfolios.reduce((acc, portfolio) => {
          acc[portfolio.portfolioId] = {
            ...portfolio,
            items: Array.isArray(portfolio.items) ? portfolio.items : [],
          };
          return acc;
        }, {});
        dispatch({ type: 'SET_PORTFOLIO', payload: portfolioMap });
        if (portfolios.length > 0) {
          dispatch({ type: 'SELECT_PORTFOLIO', payload: portfolios[0].portfolioId });
        } else {
          dispatch({ type: 'SELECT_PORTFOLIO', payload: null });
        }
      } catch (error) {
        console.error('Error deleting portfolio:', error);
      }
    } else {
      dispatch({ type: 'DELETE_FROM_PORTFOLIO', payload: index });
    }
  };

  const handleCreatePortfolio = async (e) => {
    e.preventDefault();
    if (newPortfolioName.trim() !== '') {
      if (isAuthenticated) {
        try {
          const createdPortfolio = await createPortfolio(newPortfolioName.trim());
          console.log("Created portfolio:", createdPortfolio);
          const portfolioId = createdPortfolio.portfolioId;
          const response = await getPortfolios();
          const portfolios = response;
          const portfolioMap = portfolios.reduce((acc, portfolio) => {
            acc[portfolio.portfolioId] = {
              ...portfolio,
              items: Array.isArray(portfolio.items) ? portfolio.items : [],
            };
            return acc;
          }, {});
          dispatch({ type: 'SET_PORTFOLIO', payload: portfolioMap });
          dispatch({ type: 'SELECT_PORTFOLIO', payload: portfolioId });
          localStorage.setItem('selectedPortfolioId', portfolioId);
        } catch (error) {
          console.error('Error creating portfolio:', error);
        }
      } else {
        dispatch({ type: 'CREATE_PORTFOLIO', payload: newPortfolioName.trim() });
        dispatch({ type: 'SELECT_PORTFOLIO', payload: newPortfolioName.trim() });
        localStorage.setItem('selectedPortfolioId', newPortfolioName.trim());
      }
      setNewPortfolioName('');
    }
  };

  useEffect(() => {
    localStorage.setItem('selectedPortfolioId', state.selectedPortfolio);
  }, [state.selectedPortfolio]);

  const selectedPortfolio = state.portfolios[state.selectedPortfolio];
  console.log("Selected portfolio:", selectedPortfolio);

  if (loading) {
    return <LoadingIcon />; // Display loading icon while fetching
  }

  if (!selectedPortfolio) {
    return (
      <div className="portfolio-container">
        <h1>No portfolio selected. Please add or select a portfolio.</h1>
        <form onSubmit={handleCreatePortfolio}>
          <input
            type="text"
            value={newPortfolioName}
            onChange={(e) => setNewPortfolioName(e.target.value)}
            placeholder="Enter portfolio name"
            required
          />
          <button type="submit">Create Portfolio</button>
        </form>
      </div>
    );
  } else {
    return (
      <div className="portfolio-container">
        <h1>Portfolio Tracker</h1>
        <SearchBar />
        <div>
          <h2>Create Portfolio</h2>
        </div>
        <div className="portfolio-container">
          <form onSubmit={handleCreatePortfolio}>
            <input
              type="text"
              value={newPortfolioName}
              onChange={(e) => setNewPortfolioName(e.target.value)}
              placeholder="Enter portfolio name"
              required
            />
            <button type="submit">Create Portfolio</button>
          </form>
        </div>
        <PortfolioList />
        <div>
          <h2>
            Current Portfolio: {selectedPortfolio.portfolioName || "No portfolio selected"}
          </h2>
        </div>
        <AddItemForm />
        <PortfolioItems />
        <Footer />
      </div>
    );
  }
};

export default Portfolio;
