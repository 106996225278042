import React from 'react';
import PortfolioList from './clientportfoliolist';
import NetWorth from './clientnetworth';
import ReportGeneration from './clientreport';
import IncomeAnalytics from './clientincomeanalytics';
import CreditComponent from './clientcredit';

const ClientDashboard = ({ client }) => {
  return (
    <div className="client-dashboard">
      <h1>{client.clientName}'s Dashboard</h1>
      {/* <PortfolioList portfolios={client.portfolios} />
      <NetWorth assets={client.assets} liabilities={client.liabilities} />
      <ReportGeneration client={client} />
      <IncomeAnalytics income={client.income} />
      <CreditComponent creditScore={client.creditScore} /> */}
    </div>
  );
};

export default ClientDashboard;
