// src/components/Navbar.js
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../components/infrastructure/userPool'; // Adjust the path as needed
import { AuthContext } from '../components/infrastructure/authContext'
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import "../components/styles/Navbar.css"
import SearchBar from "../components/infrastructure/search"

const Navbar = () => {
  const { isAuthenticated, signOut } = useContext(AuthContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="nav-bar">
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/rates">Rates</Link>
          </li>
          <li>
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link to="/clients">Clients</Link>
          </li>
          <li className="login-status-icon" onClick={toggleDropdown}>
            <i className={`fas ${isAuthenticated ? 'fa-user-check logged-in' : 'fa-user-times not-logged-in'}`}></i>
            {dropdownOpen && (
              <ul className="dropdown-menu">
                {!isAuthenticated ? (
                  <>
                    <li>
                      <Link to="/signup">Sign Up</Link>
                    </li>
                    <li>
                      <Link to="/login">Login</Link>
                    </li>
                  </>
                ) : (
                  <li>
                    <button onClick={signOut}>Logout</button>
                  </li>
                )}
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
