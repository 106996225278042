import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

 // base API URL
 const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";


const VolumeChart = (props) => {
  const [volData1Y, setvolData1Y] = useState("");

  // gets the volume values from the volume data use state variable and parses the data
  // first creating an empty array, then looping over the volume data appending the ONLY the volume data values to the list. NO keys.
  const getVolumeValues = () => {
    let emptyArray = [];
    for (let k = 0; k < Object.keys(volData1Y).length; k++) {
      let newVal = Object.values(volData1Y)[k].Volume;
      emptyArray.push(newVal);
    }
    return emptyArray;
  };

  // assigns the Volumes variable to the 'getolumeValues' function, returning only the volume data points
  let Volumes = getVolumeValues();

  // fetches the 1 year volume from the flask api with input (ticker)
  // updates the volume data use state object and handles the errors
  const fetch1yVolume = async (search) => {
    try {
      const response = await fetch(
        `${BASE_API_URL}/Stock/${search}/volume/1y`
      );
      const data = await response.json();
      setvolData1Y(data);
    } catch (e) {
      console.log(e);
      setvolData1Y(0);
    }
  };

  // fuction to handle the settings of the chart which displays the volume data
  // for the data of the chart, it uses the 'volumes' variable which is defines below
  const setChart = () => {
    const data = {
      labels: Object.keys(volData1Y),
      datasets: [
        {
          label: "Volume",
          backgroundColor: "rgb(255, 99, 132)",
          borderColor: "rgb(255, 99, 132)",
          data: Volumes,
        },
      ],
    };
    return data;
  };

  // Add global styles for a dark and modern theme
  const options = {
    plugins: {
      legend: {
        display: false, // Set display to false to hide the legend
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(255, 255, 255, 0.7)", // X-axis tick color
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },

        ticks: {
          color: "rgba(255, 255, 255, 0.7)", // Y-axis tick color
          display: false,
        },
      },
    },
  };
  // use effect calls the api fetch function once upon search prop change
  useEffect(() => {
    fetch1yVolume(props.stock);
  }, [props.stock]);

  return (
    <div >
      <Bar data={setChart()} options={options}width={'650'}/>
    </div>
  );
};

export default VolumeChart;
