import React, { useState, useEffect } from "react";
import YieldComponent from "./yieldchart";

// main function that creates yield curve and yeild table
const YieldCurve = () => {
  const [initdate, setDate] = useState(0);
  const [awsYields, setawsYields] = useState(0);


  const handleAWSFetch = async () => {
    const response = await fetch('https://2s6tchd4n1.execute-api.us-east-2.amazonaws.com/treasury/yields');
    const data = await response.json();
    // setawsYields(data)
    return data
  }

  console.log(handleAWSFetch())

  // use effect to call api once
  useEffect(() => {
      handleAWSFetch();
  },[])  


  

  // returns the chart and table, and selector
  return (
    <div id="chart-area">
    <YieldComponent />
    
      


      <br />
      <div className="Yield-Table">
        <h5>
        </h5>
        
      </div>
      
    </div>
  );
};

export default YieldCurve;
