import React, { useState, useEffect } from 'react';

const BusinessDescription = (props) => {
  const [BusinessDescription, setBusinessDescription] = useState("Loading...");
  const [error, setError] = useState(null);

  // base API URL
  const BASE_API_URL = "https://flask-service.ai1sil5h3i4la.us-east-2.cs.amazonlightsail.com";

  const fetchData = async (search) => {
    try {
      const response = await fetch(`${BASE_API_URL}/Stock/${search}/description`);
      if (!response.ok) {
        throw new Error('Failed to fetch stock information');
      }
      const data = await response.json();
 
      setBusinessDescription(data);
      setError(null); // Reset error state on successful data fetch
    } catch (error) {
      console.error('Error fetching stock information:', error);
      setBusinessDescription(null); // Set BusinessDescription to null on error
      setError(error.message || 'An error occurred while fetching data');
    }
  };

  useEffect(() => {
    fetchData(props.stock);
  }, [props.stock]);

  return (
    <div>
      <h2>Business Description</h2>
      <div>
        {error || BusinessDescription === null ? (
          <p>Not Applicable</p>
        ) : (
          <p>{BusinessDescription}</p>
        )}
      </div>
    </div>
  );
};

export default BusinessDescription;
