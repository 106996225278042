import react from "react"
import Signin from "../components/infrastructure/signin"

const Login = () => {
    return(

    <div>
        <h2>Signin</h2>
        <Signin />
    </div>

    )
}

export default Login