import React, { useState, useEffect, useContext } from 'react';
import useApiClients from '../components/hooks/useApiClients';
import { AuthContext } from '../components/infrastructure/authContext'; // Ensure this path is correct
import { getCurrentUserId } from '../components/infrastructure/userPool';
import { Link } from 'react-router-dom';

import "../components/styles/Clients.style.css"; // Import your styles
import LoadingIcon from '../components/infrastructure/loadingicon'; // Import the loading icon

const Clients = () => {
  const { isAuthenticated } = useContext(AuthContext); // Use AuthContext to get authentication status
  const { createClientProfile, getClientProfiles, updateClientProfile, deleteClientProfile } = useApiClients();
  const [clients, setClients] = useState([]);
  const [newClient, setNewClient] = useState({
    clientName: '',
    dob: '',
    occupation: '',
    income: '',
    state: '',
    goals: ''
  });
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        if (!isAuthenticated) {
          setAuthError('User is not authenticated.');
          setLoading(false); // Ensure loading state is updated
          return;
        }

        const userId = await getCurrentUserId();
        if (!userId) {
          throw new Error('User ID is missing.');
        }

        const data = await getClientProfiles();
        setClients(data);
        setAuthError(null); // Clear any previous authentication error
      } catch (error) {
        console.error('Error fetching clients:', error);
        setAuthError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, [isAuthenticated, getClientProfiles]);

  const handleCreateClient = async (e) => {
    e.preventDefault();
    try {
      const data = await createClientProfile(newClient);
      setClients([...clients, data]);
      setNewClient({
        clientName: '',
        dob: '',
        occupation: '',
        income: '',
        state: '',
        goals: ''
      });
    } catch (error) {
      console.error('Error creating client:', error);
    }
  };

  const handleUpdateClient = async (clientId, updatedClient) => {
    try {
      const data = await updateClientProfile(clientId, updatedClient);
      setClients(clients.map(client => (client.clientId === clientId ? data : client)));
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteClientProfile(clientId);
      setClients(clients.filter(client => client.clientId !== clientId));
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  if (loading) {
    return <LoadingIcon />; // Display the loading icon while loading
  }

  if (authError) {
    return <div>{authError}</div>; // Display authentication error
  }

  return (
    <div>
      <h1>Clients</h1>
      <div>
        <h2>Create New Client</h2>
        <form onSubmit={handleCreateClient}>
          <input
            type="text"
            placeholder="Client Name"
            value={newClient.clientName}
            onChange={e => setNewClient({ ...newClient, clientName: e.target.value })}
          />
          <input
            type="date"
            placeholder="Date of Birth"
            value={newClient.dob}
            onChange={e => setNewClient({ ...newClient, dob: e.target.value })}
          />
          <input
            type="text"
            placeholder="Occupation"
            value={newClient.occupation}
            onChange={e => setNewClient({ ...newClient, occupation: e.target.value })}
          />
          <input
            type="number"
            placeholder="Income"
            value={newClient.income}
            onChange={e => setNewClient({ ...newClient, income: e.target.value })}
          />
          <input
            type="text"
            placeholder="State"
            value={newClient.state}
            onChange={e => setNewClient({ ...newClient, state: e.target.value })}
          />
          <input
            type="text"
            placeholder="Goals"
            value={newClient.goals}
            onChange={e => setNewClient({ ...newClient, goals: e.target.value })}
          />
          <button type="submit">Create Client</button>
        </form>
      </div>
      <div>
      <h2>Existing Clients</h2>
        {clients.map(client => (
          <div key={client.clientId}>
            <h3>
              <Link to={`/client/${client.clientId}`}>{client.clientName}</Link>
            </h3>
            <p>Date of Birth: {client.dob}</p>
            <p>Occupation: {client.occupation}</p>
            <p>Income: {client.income}</p>
            <p>State: {client.state}</p>
            <p>Goals: {client.goals}</p>
            <button onClick={() => handleDeleteClient(client.clientId)}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;