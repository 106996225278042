import SearchBar from "../components/infrastructure/search";

import "../components/styles/Home.style.css"





// reutrns the home page for the entire app. Also includes the search bar component
const Home = () => {
    return( 
    <div>
        <div className="homepage">
        <br></br>
        <h1>Global Financial Lab</h1>
        <SearchBar />
        <br></br>
        
    </div>
    </div>
    );
  };
  
  export default Home;
  