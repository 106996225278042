// src/components/LoadingIcon.js
import React from 'react';
import '../styles/loadingicon.style.css'; // Import your styles

const LoadingIcon = () => (
  <div className="loading-icon">
    {/* You can use an SVG or CSS animation for the loading icon */}
    <div className="spinner"></div>
  </div>
);

export default LoadingIcon;
