import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useApiClients from '../components/hooks/useApiClients';
import { AuthContext } from '../components/infrastructure/authContext';
import LoadingIcon from '../components/infrastructure/loadingicon'; // Import the loading icon
import '../components/styles/ClientResults.style.css';
import ClientDashboard from '../components/clients/clientdashboard';

const ClientResults = () => {
  const { clientId } = useParams();
  const { isAuthenticated } = useContext(AuthContext);
  const { getClientProfiles } = useApiClients();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const fetchClient = async () => {
      try {
        if (!isAuthenticated) {
          throw new Error('User is not authenticated.');
        }

        const clients = await getClientProfiles();
        const clientData = clients.find(client => client.clientId === clientId);

        if (clientData) {
          setClient(clientData);
        } else {
          setAuthError('Client not found.');
        }
      } catch (error) {
        console.error('Error fetching client:', error);
        setAuthError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [isAuthenticated, clientId, getClientProfiles]);

  if (loading) {
    return <LoadingIcon />;
  }

  if (authError) {
    return <div>{authError}</div>;
  }

  if (!client) {
    return <div>No client found.</div>;
  }
  

  return (
    <div className="client-results-container">
      <h1>{client.clientName}'s Profile</h1>
      <p>Date of Birth: {client.dob}</p>
      <p>Occupation: {client.occupation}</p>
      <p>Income: {client.income}</p>
      <p>State: {client.state}</p>
      <p>Goals: {client.goals}</p>
      <button onClick={() => console.log('Edit client')}>Edit</button>
      <button onClick={() => console.log('Delete client')}>Delete</button>
      <div>
        <ClientDashboard client={client}/>
      </div>
    </div>
  );
};

export default ClientResults;
